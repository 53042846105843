import {
  sendPostCallToSever,
  sendGetCallToSever,
  sendPatchCallToSever,
  sendDeleteCallToSever,
} from "../Axios/Axios";
import store from "../../redux/store";
import {
  MICRO_SERVICES,
  // PUNCHH_ORDERING_URL,
  // PUNCHH_URL,
} from "../../config/config";
import { DecryptReduxAuth } from "../General/General";
import { OrderReducer } from "../../redux/reducers/OrderReducer";
import { ProfileReducer } from "../../redux/reducers/ProfileReducer";
const OrderActions = OrderReducer.actions;
const ProfileActions = ProfileReducer.actions;

const GetRewardsAPI = () => {
  // GET AUTH STATE DATA FROM REDUX
  let auth = store.getState().auth.data;
  auth = DecryptReduxAuth(auth);

  let profile = store.getState().profile;

  // CREATE API DATA OBJECT
  let APIData = {
    method: "get",
    url: process.env.REACT_APP_PUNCHH_URL + "offers",
    data: {
      client: auth.clientId,
    },
    access_token: profile.access_token,
  };
  // SEND CALL TO SERVER TO LOGIN FROM PUNCH
  return sendPostCallToSever(
    APIData,
    MICRO_SERVICES.USERS + "/punchh-bridge"
  ).then((result) => {
    // RETURN ERROR
    if (!result.data.success) {
      let message = "";

      // GET ERROR ARRAY
      let errors = result.data.error.errors;

      // LOOP OVER ERRORS ARRAY AND RETURN FIRST ONE
      for (const item in errors) {
        message = item + " " + errors[item][0];
      }

      // RETURN ERROR
      // return { generalError: message };
      return { data: [] };
    } else {
      return { data: result.data.data };
    }
  });
};
const GetMetaRewardsAPI = () => {
  // GET AUTH STATE DATA FROM REDUX
  let auth = store.getState().auth.data;
  auth = DecryptReduxAuth(auth);

  let profile = store.getState().profile;

  // CREATE API DATA OBJECT
  let APIData = {
    method: "get",
    url: process.env.REACT_APP_PUNCHH_URL + "meta",
    data: {
      client: auth.clientId,
    },
    access_token: profile.access_token,
  };
  // SEND CALL TO SERVER TO LOGIN FROM PUNCH
  return sendPostCallToSever(
    APIData,
    MICRO_SERVICES.USERS + "/punchh-bridge"
  ).then((result) => {
    // RETURN ERROR
    if (!result.data.success) {
      let message = "";

      // GET ERROR ARRAY
      let errors = result.data.error.errors;

      // LOOP OVER ERRORS ARRAY AND RETURN FIRST ONE
      for (const item in errors) {
        message = item + " " + errors[item][0];
      }

      // RETURN ERROR
      // return { generalError: message };
      return { data: [] };
    } else {
      return { data: result.data.data };
    }
  });
};

const GetUserPointsAPI = () => {
  // GET AUTH STATE DATA FROM REDUX
  let auth = store.getState().auth.data;
  auth = DecryptReduxAuth(auth);

  let profile = store.getState().profile;

  // CREATE API DATA OBJECT
  let APIData = {
    method: "get",
    url: process.env.REACT_APP_PUNCHH_URL + "checkins/account_balance",
    data: {
      client: auth.clientId,
    },
    access_token: profile.access_token,
  };
  // SEND CALL TO SERVER TO LOGIN FROM PUNCH
  return sendPostCallToSever(
    APIData,
    MICRO_SERVICES.USERS + "/punchh-bridge"
  ).then((result) => {
    // RETURN ERROR
    if (!result.data.success) {
      let message = "";

      // GET ERROR ARRAY
      let errors = result.data.error.errors;

      // LOOP OVER ERRORS ARRAY AND RETURN FIRST ONE
      for (const item in errors) {
        message = item + " " + errors[item][0];
      }

      // RETURN ERROR
      // return { generalError: message };
      return { data: [] };
    } else {
      return { data: result.data.data };
    }
  });
};

const GetSuggestionItemsAPI = (location_id) => {
  // SEND CALL TO SERVER TO GET SUGGESTIONS
  return sendGetCallToSever(
    MICRO_SERVICES.MENUS + "/suggestion-items?locationId=" + location_id
  ).then((response) => {
    // RETURN ERROR
    if (response.error) {
      return { generalError: response.error };
    } else {
      return { data: response.data.data };
    }
  });
};

const CalculateOrderDataAPI = (data) => {
  // SEND CALL TO SERVER TO GET SUGGESTIONS
  if (data.items.length > 0)
    return sendPostCallToSever(
      data,
      MICRO_SERVICES.ORDERS + "/menu-order/calculate"
    ).then((response) => {
      // RETURN ERROR
      if (response.error) {
        return {
          generalError: response.error.message
            ? response.error.message
            : typeof response.error === "array"
            ? response.error[0]
            : response.error,
        };
      } else if (response.data.error) {
        return {
          generalError: response.data.error.message
            ? response.data.error.message
            : response.data.error.error,
        };
      } else if (!response.data.success) {
        return {
          generalError: response.data.message
            ? response.data.message
            : response.data.error.message
            ? response.data.error.message
            : response.data.error.error,
        };
      } else {
        return { data: response.data.data };
      }
    });
};

const SubmitOrderDataAPI = (data) => {
  // SEND CALL TO SERVER TO GET SUGGESTIONS
  return sendPostCallToSever(
    data,
    MICRO_SERVICES.ORDERS + "/menu-order/submit"
  ).then((response) => {
    // RETURN ERROR
    if (response.data.error) {
      return {
        generalError: response.data.error.message
          ? response.data.error.message
          : response.data.error.error
          ? response.data.error.error
          : response.data.error,
      };
    } else if (!response.data.success) {
      return {
        generalError: response.data.message
          ? response.data.message
          : response.data.error.error,
      };
    } else {
      return { data: response.data.data };
    }
  });
};

const UpdateOrderDetailAPI = (data) => (dispatch) => {
  dispatch(OrderActions.UpdateCalculateOrderData({ order_data: data }));
};

const UpdateGroupOrderBasicDetailAPI = (data) => (dispatch) => {
  dispatch(
    OrderActions.UpdateGroupOrderBasicDetail({ group_order_basic_detail: data })
  );
};
const RemoveGroupOrderBasicDetailAPI = (dispatch) => {
  dispatch(OrderActions.RemoveGroupOrderBasicDetail());
};

const ResetCartDetailAPI = async (dispatch) => {
  let profile = store.getState().profile;
  let groupOrderDetail = store.getState().order.group_basic_detail;
  if (
    groupOrderDetail &&
    groupOrderDetail.user_id === profile.user_id &&
    profile.user_id
  ) {
    await cancelGroupOrderAPI(groupOrderDetail.idGroupOrder);
  }
  dispatch(OrderActions.ResetCartDetail());
};

const updateCartDeliveryInstructionsAPI =
  (deliveryInstruction) => (dispatch) => {
    dispatch(
      OrderActions.updateCartDeliveryInstructions({ deliveryInstruction })
    );
  };

const UpdateCardsListAPI = (data) => (dispatch) => {
  dispatch(ProfileActions.UpdateCardsList({ cardsList: data }));
};

const UpdateUserPointsForOrderAPI = (data) => {
  // GET AUTH STATE DATA FROM REDUX
  let auth = store.getState().auth.data;
  auth = DecryptReduxAuth(auth);

  let profile = store.getState().profile;

  // CREATE API DATA OBJECT
  let APIData = {
    method: "post",
    url: process.env.REACT_APP_PUNCHH_URL + "redemptions/redeemable",
    data: {
      client: auth.clientId,
      reedemable_id: data,
    },
    access_token: profile.access_token,
  };
  // SEND CALL TO SERVER TO UPDATE POINTS
  return sendPostCallToSever(
    APIData,
    MICRO_SERVICES.USERS + "/punchh-bridge"
  ).then((response) => {
    // RETURN ERROR
    if (response.error) {
      return { generalError: response.error };
    } else {
      return { data: response.data.data };
    }
  });
};

const UpdateUserPointsForOrderNewAPI = (redeemId) => {
  // GET AUTH STATE DATA FROM REDUX
  let auth = store.getState().auth.data;
  auth = DecryptReduxAuth(auth);

  let profile = store.getState().profile;

  // CREATE API DATA OBJECT
  let APIData = {
    method: "post",
    url: process.env.REACT_APP_PUNCHH_URL + "redemptions/redeemable",
    data: {
      client: auth.clientId,
      redeemable_id: redeemId,
      location_id: 359518,
      gps_accuracy: 27,
    },
    access_token: profile.access_token,
  };
  // SEND CALL TO SERVER TO UPDATE POINTS
  return sendPostCallToSever(
    APIData,
    MICRO_SERVICES.USERS + "/punchh-bridge"
  ).then((response) => {
    // RETURN ERROR
    if (response.error) {
      return { generalError: response.error };
    } else {
      return { data: response.data.data };
    }
  });
};

const GetApplicableOffersAPI = () => {
  // GET AUTH STATE DATA FROM REDUX
  let auth = store.getState().auth.data;
  auth = DecryptReduxAuth(auth);

  let profile = store.getState().profile;

  // CREATE API DATA OBJECT
  let APIData = {
    method: "get",
    url: process.env.REACT_APP_PUNCHH_ORDERING_URL + "checkins/balance",
    data: {
      client: auth.clientId,
    },
    access_token: profile.access_token,
    punchh_app: "ordering",
  };
  // SEND CALL TO SERVER TO UPDATE POINTS
  return sendPostCallToSever(
    APIData,
    MICRO_SERVICES.USERS + "/punchh-bridge"
  ).then((response) => {
    // RETURN ERROR
    if (response.error) {
      return { generalError: response.error };
    } else {
      return { data: response.data.data.redeemables };
    }
  });
};

const updateCalculateResponse = (data) => (dispatch) => {
  dispatch(OrderActions.UpdateCalculatedOrder(data));
};

const getRewardRedeemableId = async (reward_id, rewardType) => {
  let reward_amount = 0;
  let auth = store.getState().auth.data;
  auth = DecryptReduxAuth(auth);
  let order = store.getState().order;
  let orderData = order.calculate_order_data;
  let businessKey = auth.businessKey;
  let locationToken = order.calculated_order.punchhLocationToken;

  let menu_items = [];
  orderData.items.map((item, i) => {
    menu_items.push({
      item_name: item.itemName,
      item_qty: item.quantity,
      item_amount: item.quantity * item.price,
      menu_item_type: "M",
      menu_item_id: item.itemIdBrink,
      menu_family: "",
      menu_major_group: "",
      serial_number: i + 1 + ".0",
    });
    item.modifierItems.map((modifierItem, j) => {
      menu_items.push({
        item_name: modifierItem.itemName,
        item_qty: 1,
        item_amount: modifierItem.itemPrice,
        menu_item_type: "M",
        menu_item_id: modifierItem.itemIdBrink,
        menu_family: "",
        menu_major_group: "",
        serial_number: i + 1 + "." + (j + 1),
      });
    });
  });
  let objectData = {
    email: orderData.user_info.emailAddress,
    discount_type:
      rewardType === "redeem_reward_custom" ? "reward" : rewardType,
    employee_id: orderData.user_info.userId,
    employee_name:
      orderData.user_info.userFirstName +
      " " +
      orderData.user_info.userLastName,
    menu_items: menu_items,
    subtotal_amount: order.calculate_order_data.payment_info.totalAmount,
    receipt_amount: order.calculate_order_data.payment_info.totalAmount,
    receipt_datetime: new Date(),
    transaction_no: 0,
    external_uid: "",
    punchh_key: "",
    channel: "pos",
  };
  if (rewardType === "redeem_reward_custom") objectData.reward_id = reward_id;
  else if (rewardType === "reward") objectData.reward_id = reward_id;
  else if (rewardType === "redeemable") objectData.redeemable_id = reward_id;
  else objectData.redemption_code = reward_id;
  let result = await GetPossibleRedemptionRewardsAPI(
    objectData,
    businessKey,
    locationToken
  );

  if (result.data.category === "redeemable") {
    if (rewardType === "redeemable") {
      reward_amount = result.data.redemption_amount;
    } else {
      reward_amount = result.data.redemption_amount;
    }
  } else {
    reward_id = "";
  }
  return { reward_amount, reward_id };
};

const GetPossibleRedemptionRewardsAPI = (
  objectData,
  businessKey,
  locationToken
) => {
  // GET AUTH STATE DATA FROM REDUX
  let auth = store.getState().auth.data;
  auth = DecryptReduxAuth(auth);
  // CREATE API DATA OBJECT
  let PostUrl = process.env.REACT_APP_PUNCHH_URL.replace(
    "api2/mobile/",
    "api/pos/"
  );
  let APIData = {
    method: "post",
    url: PostUrl + "redemptions/possible",
    data: objectData,
    business_token: businessKey,
    location_token: locationToken,
  };
  // SEND CALL TO SERVER TO LOGIN FROM PUNCH
  return sendPostCallToSever(
    APIData,
    MICRO_SERVICES.USERS + "/punchh-bridge/possible-redemptions"
  ).then((result) => {
    // RETURN ERROR
    if (!result.data.success) {
      let message = "";

      // GET ERROR ARRAY
      let errors = result.data.error.errors;

      // LOOP OVER ERRORS ARRAY AND RETURN FIRST ONE
      for (const item in errors) {
        message = item + " " + errors[item][0];
      }

      // RETURN ERROR
      // return { generalError: message };
      return { data: [] };
    } else {
      return { data: result.data.data };
    }
  });
};

// GROUP ORDER APIS
const createGroupOrderDetailAPI = async (data) => {
  // SEND CALL TO SERVER TO GET SUGGESTIONS
  const response = await sendPostCallToSever(
    data,
    MICRO_SERVICES.ORDERS + "/group-order/create"
  );
  // RETURN ERROR
  if (response.data.error) {
    return {
      generalError: response.data.error.message
        ? response.data.error.message
        : response.data.error.error
        ? response.data.error.error
        : response.data.error,
    };
  } else if (!response.data.success) {
    return {
      generalError: response.data.message
        ? response.data.message
        : response.data.error.error,
    };
  } else {
    return { data: response.data.data };
  }
};
const getGroupOrderDetailAPI = async (id) => {
  // SEND CALL TO SERVER TO GET SUGGESTIONS
  const response = await sendGetCallToSever(
    MICRO_SERVICES.ORDERS + "/group-order/detail/" + id
  );
  // RETURN ERROR
  if (response.error) {
    return {
      generalError: response.error,
    };
  } else {
    return { data: response.data.data };
  }
};

const getActiveGroupOrderAPI = async () => {
  // SEND CALL TO SERVER TO GET SUGGESTIONS
  const response = await sendGetCallToSever(
    MICRO_SERVICES.ORDERS + "/group-order/active-group-order"
  );
  // RETURN ERROR
  if (response.data.error) {
    return {
      generalError: response.data.error.message
        ? response.data.error.message
        : response.data.error.error
        ? response.data.error.error
        : response.data.error,
    };
  } else if (!response.data.success) {
    return {
      generalError: response.data.message
        ? response.data.message
        : response.data.error.error,
    };
  } else {
    return { data: response.data.data };
  }
};
const cancelGroupOrderAPI = async (id) => {
  // SEND CALL TO SERVER TO GET SUGGESTIONS
  const response = await sendPostCallToSever(
    { idGroupOrder: id },
    MICRO_SERVICES.ORDERS + "/group-order/cancel-group-order"
  );
  // RETURN ERROR
  if (response.data.error) {
    return {
      generalError: response.data.error.message
        ? response.data.error.message
        : response.data.error.error
        ? response.data.error.error
        : response.data.error,
    };
  } else if (!response.data.success) {
    return {
      generalError: response.data.message
        ? response.data.message
        : response.data.error.error,
    };
  } else {
    return { data: response.data.data };
  }
};
const AddItemsToGroupOrder = async (data) => {
  const response = await sendPostCallToSever(
    data,
    MICRO_SERVICES.ORDERS + "/group-order-items/add"
  );
  
  // RETURN ERROR
  if (response.error) {
    return {
      generalError: response.error,
    };
  } else {
    return { idGroupOrderItems: response.data.data.idGroupOrderItems };
  }
};
const GetGroupOrderItemsList = async (id) => {
  const response = await sendGetCallToSever(
    MICRO_SERVICES.ORDERS + "/group-order/refresh/" + id
  );
  return response.data.data.groupItems;
};
const UpdateUserGroupOrderItems = async (data, id) => {
  const response = await sendPatchCallToSever(
    data,
    MICRO_SERVICES.ORDERS + "/group-order-items/update/" + id
  );
  // return response.data.data.groupItems;
};
const DeleteUserGroupOrderItems = async (data) => {
  const response = await sendPostCallToSever(
    data,
    MICRO_SERVICES.ORDERS + "/group-order-items/remove-item"
  );
  // return response;
};
const leaveOrderGroupAPI = async (data) => {
  let response = await sendPostCallToSever(
    data,
    MICRO_SERVICES.ORDERS + "/group-order-items/leave-group-order"
  );
};
export {
  GetRewardsAPI,
  GetSuggestionItemsAPI,
  UpdateOrderDetailAPI,
  CalculateOrderDataAPI,
  SubmitOrderDataAPI,
  ResetCartDetailAPI,
  updateCartDeliveryInstructionsAPI,
  UpdateCardsListAPI,
  UpdateUserPointsForOrderAPI,
  UpdateUserPointsForOrderNewAPI,
  GetApplicableOffersAPI,
  GetUserPointsAPI,
  GetMetaRewardsAPI,
  updateCalculateResponse,
  getRewardRedeemableId,
  UpdateGroupOrderBasicDetailAPI,
  createGroupOrderDetailAPI,
  getGroupOrderDetailAPI,
  cancelGroupOrderAPI,
  AddItemsToGroupOrder,
  GetGroupOrderItemsList,
  UpdateUserGroupOrderItems,
  DeleteUserGroupOrderItems,
  getActiveGroupOrderAPI,
  leaveOrderGroupAPI,
  RemoveGroupOrderBasicDetailAPI,
};
