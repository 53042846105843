import moment from "moment-timezone";
import {
  AddItemsToGroupOrder,
  CalculateOrderDataAPI,
  DeleteUserGroupOrderItems,
  GetApplicableOffersAPI,
  GetGroupOrderItemsList,
  GetRewardsAPI,
  GetUserPointsAPI,
  ResetCartDetailAPI,
  UpdateOrderDetailAPI,
  UpdateUserGroupOrderItems,
  getActiveGroupOrderAPI,
  getRewardRedeemableId,
  leaveOrderGroupAPI,
  updateCalculateResponse,
} from "../../actions/Cart/Cart";
import {
  CalculateTotalAmountOfOrder,
  CreateGoogleAnalyticsAPI,
  updateCartRewards,
} from "../../actions/General/General";
import store from "../../redux/store";
import {
  DeleteFavoriteItemAPI,
  SetFavoriteMenuListAPI,
} from "../../actions/Orders/Order";
import MessagesList from "../../config/messages.json";
import { v4 as uuidv4 } from "uuid";

const GetOrderType = (order_detail) => {
  if (order_detail.isGroupOrder) {
    if (order_detail.bOrderAsParticipant) {
      if (order_detail.items.length > 0) return "GroupParticipant";
      else return "GroupParticipantNoItems";
    } else {
      return "GroupAdmin";
    }
  } else if (
    order_detail &&
    order_detail.items &&
    order_detail.items.length > 0
  )
    return "Normal";
  return "NormalNoItems";
};

const itemNotAvailableError = (
  response,
  orderData,
  setNotAvailableItemId,
  setErrorMessage,
  setErrorType
) => {
  let itemName = response.generalError;
  itemName = itemName.replace("ItemId ", "");
  itemName = itemName.replace(" is invalid", "");
  let isIngredientError = true;

  let item = orderData.items.filter(
    (singleItem) => singleItem.itemIdBrink === parseInt(itemName)
  );
  let originalItemName = item.length > 0 ? item[0].itemName : "";
  if (originalItemName === "") {
    orderData.items.map((singleItem) =>
      singleItem.modifierItems.filter((singleModifierItem) => {
        if (singleModifierItem.itemIdBrink === parseInt(itemName))
          item.push(singleModifierItem.itemName);
      })
    );
    originalItemName = item.length > 0 ? item[0] : "";
  } else {
    isIngredientError = false;
  }

  let errorString = isIngredientError
    ? MessagesList["remove not available ingredient item from cart"]
    : MessagesList["remove not available item from cart"];

  errorString = errorString.replace("{{ITEM_NAME}}", originalItemName);
  setErrorType("itemAvailability");
  setErrorMessage(errorString);
  setNotAvailableItemId(parseInt(itemName));
};

const CancelGroupOrder = async (dispatch) => {
  await ResetCartDetailAPI(dispatch);
};

const AddAdminItemsToGroupCart = async (orderData, dispatch) => {
  if (orderData.isGroupOrder && !orderData.bOrderAsParticipant) {
    let profileDetail = store.getState().profile;
    let basicGroupDetail = store.getState().order.group_basic_detail;

    if (basicGroupDetail.user_id === profileDetail.user_id) {
      let adminAddedItemsList = [];
      adminAddedItemsList = orderData.items.filter(
        (singleItem) =>
          !singleItem.hasOwnProperty("userId") ||
          singleItem.userId === profileDetail.user_id
      );
      let adminGroupItemsCount = 0;
      let adminGroupItemsDetailId = "";
      adminAddedItemsList.map((adminSingleItem) => {
        if (adminSingleItem.hasOwnProperty("idGroupOrderItems")) {
          adminGroupItemsDetailId = adminSingleItem.idGroupOrderItems;
        } else {
          adminGroupItemsCount++;
        }
      });
      if (adminGroupItemsDetailId) {
        if (adminGroupItemsCount > 0) {
          let data = {
            userId: profileDetail.user_id,
            items: adminAddedItemsList,
          };
          await UpdateUserGroupOrderItems(data, adminGroupItemsDetailId);
          orderData = _.cloneDeep(orderData);
          let orderItems = _.cloneDeep(orderData.items);
          for (let i = 0; i < orderItems.length; i++) {
            if (!orderItems[i].hasOwnProperty("idGroupOrderItems")) {
              orderItems[i] = {
                ...orderItems[i],
                idGroupOrderItems: adminGroupItemsDetailId,
                userId: orderData.user_info.userId,
              };
            }
          }
          orderData.items = orderItems;
          dispatch(UpdateOrderDetailAPI(orderData));
        }
      } else {
        if (adminAddedItemsList.length > 0)
          await addGroupOrderParticipantItemsDetail(
            dispatch,
            adminAddedItemsList
          );
      }
    }
  }
};
const RecheckRewardValidation = async (orderData, dispatch) => {
  let appliedReward =
    orderData.discounts && orderData.discounts[0]
      ? orderData.discounts[0]
      : null;
  let res = null;
  let message = "";
  if (appliedReward) {
    let rewardType = appliedReward.rewardType;
    let reward = {
      name: appliedReward.name,
    };
    if (appliedReward.rewardType === "redeemable")
      reward.redeemable_id = appliedReward.rewardId;
    else if (appliedReward.rewardType === "reward")
      reward.reward_id = appliedReward.rewardId;
    if (appliedReward.rewardType === "redemption_code") {
      let result = await getRewardRedeemableId(
        appliedReward.rewardId,
        "redemption_code",
        orderData
      );
      if (result.reward_id !== "") {
        // CLONE ORDER DATA FROM REDUX STATE TO LOCAL VARIABLE FOR ORDER DATA
        let orderItem = _.cloneDeep(orderData);
        orderItem = await updateCartRewards(
          result.reward_id,
          // promoCode,
          "Promo Code",
          result.reward_amount,
          "redemption_code",
          orderItem,
          ""
        );

        // IF CLICKED REWARD IS ALREADY SELECTED, THEN EMPTY THE REWARDS OTHER ADD REWARD
        dispatch(UpdateOrderDetailAPI(_.cloneDeep(orderItem)));
      } else {
        res = "";
      }
    } else {
      res = await updateRewardDetailInCart(reward, rewardType, dispatch);
      let offers = [];

      if (appliedReward.rewardType === "redeemable") {
        let responses = await GetApplicableOffersAPI();
        // SET LOCAL STATES FOR REWARDS, SUGGESTIONS, ORDER DETAIL
        offers = responses.data;
      }
      if (appliedReward.rewardType === "reward") {
        let responses = await GetRewardsAPI();
        // SET LOCAL STATES FOR REWARDS, SUGGESTIONS, ORDER DETAIL
        offers = responses.data.hasOwnProperty("rewards")
          ? responses.data.rewards
          : [];
      }

      offers.map((sinlgeOffer) => {
        // console.log("sinlgeOffer", sinlgeOffer);
        if (
          appliedReward.rewardType === "redeemable"
            ? sinlgeOffer.redeemable_id
            : sinlgeOffer.reward_id
        )
          message = sinlgeOffer.description;
      });
    }
  }
  return { res, message };
};
const CalculateOrder = async (
  orderData,
  timezone,
  setOrderDetailData,
  setErrorTitle,
  setErrorMessage,
  setErrorType,
  setNotAvailableItemId,
  dispatch
) => {
  orderData = _.cloneDeep(orderData);

  let orderResponse = await CalculateOrderDataAPI(orderData);
  if (
    moment()
      .tz(timezone ? timezone : "America/Los_Angeles")
      .unix() < orderData.orderDate ||
    orderData.bOrderAsParticipant
  ) {
    setErrorTitle("");
    if (orderResponse.data) {
      dispatch(updateCalculateResponse(orderResponse.data));
      setOrderDetailData(
        orderResponse && orderResponse.data ? orderResponse.data : ""
      );
      if (
        orderResponse.data &&
        orderResponse.data.orderDate &&
        orderData.orderDate + 15 * 60 < orderResponse.data.orderDate &&
        !orderData.bOrderAsParticipant
      ) {
        setErrorType("time");
        let newTimeString = " time is";
        if (
          moment
            .tz(orderData.orderDate * 1000, orderResponse.data.locationTimeZone)
            .format("L") !==
          moment
            .tz(
              orderResponse.data.orderDate * 1000,
              orderResponse.data.locationTimeZone
            )
            .format("L")
        ) {
          newTimeString =
            " date and time is " +
            moment
              .tz(
                orderResponse.data.orderDate * 1000,
                orderResponse.data.locationTimeZone
              )
              .format("DD/MM/YYYY") +
            " at";
        }
        newTimeString +=
          " " +
          moment
            .tz(
              orderResponse.data.orderDate * 1000,
              orderResponse.data.locationTimeZone
            )
            .format("LT") +
          " - " +
          moment
            .tz(
              orderResponse.data.orderDate * 1000,
              orderResponse.data.locationTimeZone
            )
            .add(15, "minutes")
            .format("LT");
        if (orderData.orderType === "pickup") {
          setErrorTitle(MessagesList["Pickup Time Change Title"]);
          setErrorMessage(MessagesList["Pickup Time Change"] + newTimeString);
        } else {
          setErrorTitle(MessagesList["Delivery Time Change Title"]);
          setErrorMessage(MessagesList["Delivery Time Change"] + newTimeString);
        }
        await AddAdminItemsToGroupCart(orderData, dispatch);
      } else {
        setErrorMessage("");
        setErrorType("");
        await AddAdminItemsToGroupCart(orderData, dispatch);
        let rewardResponse = await RecheckRewardValidation(orderData, dispatch);
        // if (rewardResponse !== null) {
        //   if (!rewardResponse) {
        if (rewardResponse.res !== null && !rewardResponse.res) {
          let error = MessagesList["Reward Can not be used"];

          let rewardDescription = rewardResponse.message;
          rewardDescription = rewardDescription.replace(/\*.*\*/, "");
          error += "<br /><br />" + rewardDescription;

          setErrorType("reward");
          setErrorMessage(error);
          let orderItem = orderData;
          delete orderItem.discounts;
          dispatch(UpdateOrderDetailAPI(_.cloneDeep(orderItem)));
          //   }
          // }
        }
      }
    } else {
      dispatch(updateCalculateResponse(null));
      await AddAdminItemsToGroupCart(orderData, dispatch);
      if (orderResponse.generalError === "Internal result code 1")
        setErrorMessage(MessagesList["Cart Internal Error"]);
      else if (
        orderResponse.generalError ===
        "Phone number seems not valid. Please update valid phone number in your profile."
      ) {
        setErrorMessage(MessagesList["Phone number seems not valid."]);
        setErrorTitle(MessagesList["Phone number seems not valid Title."]);
        setErrorType("phone");
      } else if (orderResponse.generalError === "Failed to get location") {
        setErrorType("other");
        setErrorMessage(MessagesList[orderResponse.generalError]);
      } else if (
        orderResponse.generalError.startsWith === "No more of" &&
        orderResponse.generalError.endsWith === "is available."
      ) {
        setErrorType("other");
        let itemName = orderResponse.generalError;
        itemName = itemName.replace("No more of", "");
        itemName = itemName.replace("is available.", "");
        let errorString = MessagesList["no more item is available"];
        errorString = errorString.replace("{{ITEM_NAME}}", itemName);
        setErrorMessage(errorString);
      } else if (
        orderResponse.generalError.startsWith("ItemId ") &&
        orderResponse.generalError.endsWith(" is invalid")
      ) {
        itemNotAvailableError(
          orderResponse,
          orderData,
          setNotAvailableItemId,
          setErrorMessage,
          setErrorType
        );
      } else {
        setErrorMessage(orderResponse.generalError);
        setErrorType("other");
      }
    }
  } else {
    setErrorType("changeTime");
    setErrorMessage(
      orderData.orderType === "pickup"
        ? "Your selected Pick Up time has passed. Please select a new Pick Up time."
        : "Your selected Delivery time has passed. Please select a new Delivery time."
    );
    setErrorTitle(
      orderData.orderType === "pickup"
        ? "New Pick Up Time"
        : "New Delivery Time"
    );
  }
};
const loadAllRewards = async (
  setApplicableOffers,
  setRewards,
  setAccountBalance
) => {
  let responses = await Promise.all([
    GetRewardsAPI(),
    GetApplicableOffersAPI(),
    GetUserPointsAPI(),
  ]);
  // SET LOCAL STATES FOR REWARDS, SUGGESTIONS, ORDER DETAIL
  setRewards(
    responses[0].data.hasOwnProperty("rewards") ? responses[0].data.rewards : []
  );
  setAccountBalance(
    responses[2].data.hasOwnProperty("account_balance_details")
      ? responses[2].data.account_balance_details
      : {}
  );
  setApplicableOffers(responses[1].data);
};
const updateRewardDetailInCart = async (reward, reward_type, dispatch) => {
  let reward_id =
    reward_type === "redeemable" ? reward.redeemable_id : reward.reward_id;

  let result = await getRewardRedeemableId(reward_id, reward_type);
  let orderData = store.getState().order.calculate_order_data;
  let orderItem = _.cloneDeep(orderData);
  if (result.reward_id) {
    orderItem = await updateCartRewards(
      result.reward_id,
      reward.name,
      result.reward_amount,
      reward_type,
      orderItem
    );
    dispatch(UpdateOrderDetailAPI(_.cloneDeep(orderItem)));
    return result.reward_id;
  } else {
    return "";
  }
};

const RemoveItemFromCart = async (
  items,
  index,
  setIsCalculateAPIRequiredToCall,
  setShowError,
  dispatch
) => {
  items = _.cloneDeep(items);
  let deletedItem = items[index];
  let otherItemsArrayOfSameOrder = items.filter(
    (singleItem) =>
      singleItem.idGroupOrderItems === deletedItem.idGroupOrderItems &&
      singleItem.itemUniqueId !== deletedItem.itemUniqueId
  );
  let orderDetail = store.getState().order;
  let orderItem = _.cloneDeep(orderDetail.calculate_order_data);

  if (
    items.length > 1 ||
    (orderItem.isGroupOrder && !orderItem.bOrderAsParticipant)
  ) {
    let removedItem = items[index];

    if (removedItem.isSuggestionItem && removedItem.quantity > 1) {
      // UPDATE QUANTITY AND TOTAL PRICE
      removedItem.quantity -= 1;
      removedItem.itemTotal = parseFloat(
        (removedItem.price * removedItem.quantity).toFixed(2)
      );
    } else {
      // REMOVE THE SELECTED MENU ITEM FROM ARRAY
      items.splice(index, 1);
    }

    orderItem.items = items;
    orderItem.payment_info = CalculateTotalAmountOfOrder(orderItem);
    // UPDATE DATA IN REDUX STATE

    if (orderItem.isGroupOrder && !orderItem.bOrderAsParticipant) {
      let groupOrderDetail = orderDetail.group_basic_detail;
      if (otherItemsArrayOfSameOrder.length > 0) {
        let data = {
          userId: deletedItem.userId,
          items: otherItemsArrayOfSameOrder,
        };
        await UpdateUserGroupOrderItems(data, deletedItem.idGroupOrderItems);
      } else {
        let data = {
          userId: deletedItem.userId,
          idGroupOrderItems: deletedItem.idGroupOrderItems,
          idGroupOrder: groupOrderDetail.idGroupOrder,
        };
        await DeleteUserGroupOrderItems(data);
      }
    }
    dispatch(UpdateOrderDetailAPI(orderItem));
    setIsCalculateAPIRequiredToCall(true);
  } else {
    setShowError("other");
  }
};
const UpdateGroupItem = async (index, dispatch) => {
  let orderDetail = store.getState().order;
  let orderItem = _.cloneDeep(orderDetail.calculate_order_data);

  if (orderItem.isGroupOrder && !orderItem.bOrderAsParticipant) {
    let items = _.cloneDeep(orderItem.items);
    let updatedItem = items[index];
    let otherItemsArrayOfSameOrder = items.filter(
      (singleItem) =>
        singleItem.idGroupOrderItems === updatedItem.idGroupOrderItems
    );
    let data = {
      userId: updatedItem.userId,
      items: otherItemsArrayOfSameOrder,
    };
    await UpdateUserGroupOrderItems(data, updatedItem.idGroupOrderItems);
    await RefreshCartItems(dispatch);
  }
  return "";
};
const UpdateUtensilsInOrder = (
  hasUtensils,
  setIsCalculateAPIRequiredToCall,
  dispatch
) => {
  let orderData = store.getState().order.calculate_order_data;
  // CLONE ORDER DATA FROM REDUX STATE TO LOCAL VARIABLE FOR ORDER DATA
  let orderItem = _.cloneDeep(orderData);

  orderItem.hasUtensils = hasUtensils;
  // UPDATE DATA IN REDUX STATE
  dispatch(UpdateOrderDetailAPI(orderItem));
  setIsCalculateAPIRequiredToCall(true);
};

const AddToFavorite = async (
  cartDataItem,
  orderId,
  locationId,
  favoriteItemList,
  setFavoriteItemList,
  setFavoriteItemId,
  setLoader
) => {
  let cartDataItemUpdate = _.cloneDeep(cartDataItem);
  setFavoriteItemId(cartDataItemUpdate.itemUniqueId);
  setLoader(true);
  cartDataItemUpdate.orderId = orderId;
  cartDataItemUpdate.mixtLocationId = locationId;

  let res = await SetFavoriteMenuListAPI(cartDataItemUpdate);
  let favoritesArray = _.cloneDeep(favoriteItemList);
  favoritesArray.push({
    idFavoriteOrderItem: res.data.idFavoriteOrderItem,
    itemUniqueId: cartDataItemUpdate.itemUniqueId,
  });
  setFavoriteItemList(favoritesArray);
  CreateGoogleAnalyticsAPI("AddedToFavorite", {});
  setFavoriteItemId("");
  setLoader(false);
};

const RemoveFromFavorite = async (
  index,
  favoriteItemList,
  setFavoriteItemId,
  setFavoriteItemList,
  setLoader
) => {
  let favoritesArray = _.cloneDeep(favoriteItemList);
  let itemFavoriteId = favoritesArray[index];
  setFavoriteItemId(itemFavoriteId.itemUniqueId);
  setLoader(true);
  await DeleteFavoriteItemAPI(itemFavoriteId.idFavoriteOrderItem);
  favoritesArray.splice(index, 1);
  setFavoriteItemList(favoritesArray);
  setFavoriteItemId("");
  setLoader(false);
};

const addSuggestionItemToCart = (
  suggestionItem,
  setIsCalculateAPIRequiredToCall,
  dispatch
) => {
  // CREATE SUGGESTION ITEM FOR CART
  let suggestionAddedItem = {
    modifierMethodType: "item",
    menuItemIdBrink: 0,
    itemName: suggestionItem.itemName,
    itemIdBrink: suggestionItem.itemIdBrink,
    price: suggestionItem.price,
    itemTotal: suggestionItem.price,
    quantity: 1,
    notes: "",
    modifierItems: [],
    itemphoto: suggestionItem.itemphoto,
    isSuggestionItem: true,
    itemUniqueId: uuidv4(),
  };
  let orderData = store.getState().order.calculate_order_data;
  let profile = store.getState().profile;
  if (orderData.isGroupOrder) {
    suggestionAddedItem.notes = profile.first_name + " " + profile.last_name;
  }
  // CLONE ORDER DATA FROM REDUX STATE TO LOCAL VARIABLE FOR ORDER DATA
  let orderItem = _.cloneDeep(orderData);

  // GET MENU ITEMS LIST OF ORDER
  let items = orderItem.items;

  // CHECK SUGGESTION ITEM ALREADY EXIST IN CART
  let suggestionItemAlreadyExist = false;
  let suggestionItemIndex = 0;
  items.map((cartItem, index) => {
    if (
      cartItem.isSuggestionItem &&
      cartItem.itemIdBrink === suggestionAddedItem.itemIdBrink
    ) {
      suggestionItemIndex = index;
      // IF SUGGESTION ITEM EXIST UPDATE QUANTITY AND PRICE
      suggestionItemAlreadyExist = true;
      let quantity = cartItem.quantity + 1;

      suggestionAddedItem.quantity = quantity;
      suggestionAddedItem.itemTotal = parseFloat(
        (suggestionAddedItem.price * quantity).toFixed(2)
      );
    }
  });

  if (suggestionItemAlreadyExist) {
    items[suggestionItemIndex] = suggestionAddedItem;
  } else {
    items.push(suggestionAddedItem);
  }

  orderItem.items = items;
  orderItem.payment_info = CalculateTotalAmountOfOrder(orderItem);
  // UPDATE DATA IN REDUX STATE
  dispatch(UpdateOrderDetailAPI(orderItem));
  setIsCalculateAPIRequiredToCall(true);
};
const addGroupOrderParticipantItemsDetail = async (
  dispatch,
  itemsList = null
) => {
  let orderDetail = store.getState().order;
  let orderData = _.cloneDeep(orderDetail.calculate_order_data);
  const orderDetailData = _.cloneDeep(orderDetail.calculated_order);

  let groupDetail = orderDetail.group_basic_detail;
  let items = orderData.items;
  if (itemsList !== null) {
    items = itemsList;
  }
  let addDetailToGroupOrder = {
    orderId: orderData.orderId,
    idGroupOrder: parseInt(groupDetail.idGroupOrder),
    items: items,
    user_info: orderData.user_info,
    userName:
      orderData.user_info.userFirstName +
      " " +
      orderData.user_info.userLastName,
    userId: orderData.user_info.userId,
    totalAmount: orderData.payment_info.totalAmount,
    calc_resp_info: JSON.stringify(orderDetailData),
  };
  let result = await AddItemsToGroupOrder(addDetailToGroupOrder);
  // if (itemsList === null) ResetCartDetailAPI(dispatch);
  // else {
  if (itemsList !== null) {
    let orderItems = _.cloneDeep(orderData.items);
    for (let i = 0; i < orderItems.length; i++) {
      if (!orderItems[i].hasOwnProperty("idGroupOrderItems")) {
        orderItems[i] = {
          ...orderItems[i],
          idGroupOrderItems: result.idGroupOrderItems,
          userId: orderData.user_info.userId,
        };
      }
    }
    orderData.items = orderItems;
    dispatch(UpdateOrderDetailAPI(orderData));
  }
  return result;
};
const RefreshCartItems = async (dispatch) => {
  let orderDetail = store.getState().order;
  let groupDetail = orderDetail.group_basic_detail;
  let orderData = _.cloneDeep(orderDetail.calculate_order_data);
  let otherUsersItems = await GetGroupOrderItemsList(groupDetail.idGroupOrder);
  let ordersItemsList = _.cloneDeep(orderData.items);
  otherUsersItems.map((singleUserItem) => {
    singleUserItem.items.map((singleUserItemRecord) => {
      let isFind = false;
      for (let i = 0; i < ordersItemsList.length; i++) {
        if (
          ordersItemsList[i].hasOwnProperty("idGroupOrderItems") &&
          ordersItemsList[i].idGroupOrderItems ===
            singleUserItem.idGroupOrderItems &&
          ordersItemsList[i].itemUniqueId === singleUserItemRecord.itemUniqueId
        ) {
          isFind = true;
        }
      }

      if (!singleUserItemRecord.hasOwnProperty("itemUniqueId"))
        singleUserItemRecord.itemUniqueId = uuidv4();

      if (!isFind) {
        let newItemObj = {
          ...singleUserItemRecord,
          idGroupOrderItems: singleUserItem.idGroupOrderItems,
          userId: singleUserItem.userId,
        };
        ordersItemsList.push(newItemObj);
      }
    });
  });
  let updateOrderItemsList = [];
  ordersItemsList.map((singleOrderItem) => {
    let isItemExist = false;
    for (let i = 0; i < otherUsersItems.length; i++) {
      for (let j = 0; j < otherUsersItems[i].items.length; j++) {
        if (
          otherUsersItems[i].items[j].itemUniqueId ===
          singleOrderItem.itemUniqueId
        ) {
          isItemExist = true;
        }
      }
    }
    if (isItemExist) {
      updateOrderItemsList.push(singleOrderItem);
    }
  });

  if (!_.isEqual(updateOrderItemsList, orderData.items)) {
    orderData.items = updateOrderItemsList;
    orderData.payment_info = CalculateTotalAmountOfOrder(orderData);
    // UPDATE DATA IN REDUX STATE
    dispatch(UpdateOrderDetailAPI(orderData));
    return true;
  } else {
    return false;
  }
};

const GetCartLocationDetail = () => {
  let orderDetail = store.getState().order.calculate_order_data;
  let locationsList = store.getState().location;
  let locationId =
    orderDetail.orderType === "pickup"
      ? orderDetail.mixtLocationId
      : orderDetail.delivery_info.idUserDeliveryAddress;

  let userSelectedLocation =
    orderDetail.orderType === "pickup"
      ? locationsList.pickupLocations.filter(
          (loc) => loc.idMixtLocation === locationId
        )
      : locationsList.deliveryLocations.filter(
          (loc) => locationId === loc.idUserDeliveryAddress
        );

  return userSelectedLocation;
};
const getGroupOrderDetailOfLoggedInUser = async () => {
  let result = await getActiveGroupOrderAPI();

  if (result.data.isActive) {
    return result.data.idGroupOrder;
  } else return "";
};
const leaveOrderGroup = async () => {
  let orderDetail = store.getState().order.group_basic_detail;
  await leaveOrderGroupAPI({ idGroupOrder: orderDetail.idGroupOrder });
};
export {
  CancelGroupOrder,
  CalculateOrder,
  GetOrderType,
  loadAllRewards,
  updateRewardDetailInCart,
  RemoveItemFromCart,
  UpdateUtensilsInOrder,
  AddToFavorite,
  RemoveFromFavorite,
  addSuggestionItemToCart,
  addGroupOrderParticipantItemsDetail,
  RefreshCartItems,
  GetCartLocationDetail,
  getGroupOrderDetailOfLoggedInUser,
  leaveOrderGroup,
  UpdateGroupItem,
};
